import { Space } from 'antd';
import OrderConcreteLoadsTable from './components/OrderConcreteLoadsTable';
import UpdateConcreteLoadOrderId from './components/UpdateConcreteLoadOrderId';
import UnloadedToSigned from './components/UnloadedToSigned';
import SignedToUnloaded from './components/SignedToUnloaded';
import RevertRejected from './components/RevertRejected';

const CoreManagement = (): JSX.Element => {

    return (

        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <OrderConcreteLoadsTable />
            <UpdateConcreteLoadOrderId />
            <UnloadedToSigned />
            <SignedToUnloaded />
            <RevertRejected />
        </Space>
    );
};

export default CoreManagement;


